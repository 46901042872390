import React, { useState, useEffect } from 'react';
import {
  client,
  createCheckout,
  fetchCheckout,
  addLineItems,
  removeLineItems,
  updateLineItems,
} from '~/services/checkout';
import Context from '~/context/StoreContext';

const CheckoutProvider = ({ children }) => {
  // Initial state
  const initialStoreState = {
    client,
    isLoading: false,
    lastAdd: null,
    checkout: {
      lineItems: [],
    },
    products: [],
    shop: {},
  };

  // State
  const [store, updateStore] = useState(initialStoreState);

  useEffect(() => {
    // Initialize Checkout
    const initializeCheckout = async () => {
      // Check for an existing cart.
      const isBrowser = typeof window !== 'undefined';

      // Existing checkout
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null;

      // Checkout
      const setCheckoutInState = (checkout) => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id);
        }

        updateStore((prevState) => ({ ...prevState, checkout }));
      };

      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID);
          // Make sure this cart hasn't already been purchased.
          if (!checkout.completedAt) {
            setCheckoutInState(checkout);
            return;
          }
        } catch (e) {
          localStorage.setItem('shopify_checkout_id', null);
        }
      }
      const newCheckout = await createCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, []);

  return (
    <Context.Provider
      value={{
        store,
        addVariantToCart: async (variantId, quantity) => {
          if (variantId === '' || !quantity) {
            console.error('Both a size and quantity are required.');
            return;
          }

          const lineItemsToUpdate = [
            { variantId, quantity: parseInt(quantity, 10) },
          ];

          updateStore((prevState) => ({ ...prevState, isLoading: true }));

          const checkout = await addLineItems(
            store.checkout.id,
            lineItemsToUpdate
          );

          updateStore((prevState) => ({
            ...prevState,
            checkout,
            isLoading: false,
            lastAdd: Date.now(),
          }));
        },
        removeLineItem: async (lineItemId) => {
          updateStore((prevState) => ({ ...prevState, isLoading: true }));

          const checkout = await removeLineItems(store.checkout.id, [
            lineItemId,
          ]);

          updateStore((prevState) => ({
            ...prevState,
            checkout,
            isLoading: false,
          }));
        },
        updateLineItem: async (lineItemId, quantity) => {
          const lineItemsToUpdate = [
            { id: lineItemId, quantity: parseInt(quantity, 10) },
          ];

          updateStore((prevState) => ({ ...prevState, isLoading: true }));

          const checkout = await updateLineItems(
            store.checkout.id,
            lineItemsToUpdate
          );

          updateStore((prevState) => ({
            ...prevState,
            checkout,
            isLoading: false,
          }));
        },
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default CheckoutProvider;
