import { createStorefrontApiClient } from '@shopify/storefront-api-client';

// GraphQL fragments for checkout operations
const CheckoutFragment = `
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    totalPriceV2 {
      amount
      currencyCode
    }
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          quantity
          variant {
            id
            title
            priceV2 {
              amount
              currencyCode
            }
            compareAtPriceV2 {
              amount
              currencyCode
            }
            image {
              src
            }
            selectedOptions {
              name
              value
            }
          }
        }
      }
    }
  }
`;

// Initialize Shopify client
export const client = createStorefrontApiClient({
  storeDomain: `checkout.${process.env.SHOP_NAME}.se`,
  apiVersion: '2024-04',
  publicAccessToken: process.env.SHOPIFY_ACCESS_TOKEN,
  language: 'sv-SE',
});

// Create a new checkout
export async function createCheckout() {
  const mutation = `
    mutation checkoutCreate {
      checkoutCreate(input: {}) {
        checkout {
          ...CheckoutFragment
        }
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }
    ${CheckoutFragment}
  `;

  const { data } = await client.request(mutation);

  if (data.checkoutCreate.checkoutUserErrors.length > 0) {
    throw new Error(data.checkoutCreate.checkoutUserErrors[0].message);
  }

  return normalizeCheckout(data.checkoutCreate.checkout);
}

// Fetch an existing checkout
export async function fetchCheckout(checkoutId) {
  const query = `
    query getCheckout($checkoutId: ID!) {
      node(id: $checkoutId) {
        ... on Checkout {
          ...CheckoutFragment
        }
      }
    }
    ${CheckoutFragment}
  `;

  const { data } = await client.request(query, {
    variables: {
      checkoutId,
    },
  });

  if (!data.node) {
    throw new Error('Checkout not found');
  }

  return normalizeCheckout(data.node);
}

// Add items to checkout
export async function addLineItems(checkoutId, lineItems) {
  const mutation = `
    mutation checkoutLineItemsAdd($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
      checkoutLineItemsAdd(checkoutId: $checkoutId, lineItems: $lineItems) {
        checkout {
          ...CheckoutFragment
        }
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }
    ${CheckoutFragment}
  `;

  const { data } = await client.request(mutation, {
    variables: {
      checkoutId,
      lineItems: lineItems.map((item) => ({
        variantId: item.variantId,
        quantity: item.quantity,
      })),
    },
  });

  if (data.checkoutLineItemsAdd.checkoutUserErrors.length > 0) {
    throw new Error(data.checkoutLineItemsAdd.checkoutUserErrors[0].message);
  }

  return normalizeCheckout(data.checkoutLineItemsAdd.checkout);
}

// Remove items from checkout
export async function removeLineItems(checkoutId, lineItemIds) {
  const mutation = `
    mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
      checkoutLineItemsRemove(checkoutId: $checkoutId, lineItemIds: $lineItemIds) {
        checkout {
          ...CheckoutFragment
        }
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }
    ${CheckoutFragment}
  `;

  const { data } = await client.request(mutation, {
    variables: {
      checkoutId,
      lineItemIds,
    },
  });

  if (data.checkoutLineItemsRemove.checkoutUserErrors.length > 0) {
    throw new Error(data.checkoutLineItemsRemove.checkoutUserErrors[0].message);
  }

  return normalizeCheckout(data.checkoutLineItemsRemove.checkout);
}

// Update items in checkout
export async function updateLineItems(checkoutId, lineItems) {
  const mutation = `
    mutation checkoutLineItemsUpdate($checkoutId: ID!, $lineItems: [CheckoutLineItemUpdateInput!]!) {
      checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
        checkout {
          ...CheckoutFragment
        }
        checkoutUserErrors {
          code
          field
          message
        }
      }
    }
    ${CheckoutFragment}
  `;

  const { data } = await client.request(mutation, {
    variables: {
      checkoutId,
      lineItems: lineItems.map((item) => ({
        id: item.id,
        quantity: item.quantity,
      })),
    },
  });

  if (data.checkoutLineItemsUpdate.checkoutUserErrors.length > 0) {
    throw new Error(data.checkoutLineItemsUpdate.checkoutUserErrors[0].message);
  }

  return normalizeCheckout(data.checkoutLineItemsUpdate.checkout);
}

// Helper function to normalize checkout data
function normalizeCheckout(checkout) {
  return {
    ...checkout,
    lineItems: checkout.lineItems.edges.map((edge) => edge.node),
  };
}
